exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplikacje-webowe-js": () => import("./../../../src/pages/aplikacje-webowe.js" /* webpackChunkName: "component---src-pages-aplikacje-webowe-js" */),
  "component---src-pages-blog-aplikacje-webowe-vs-strony-internetowe-js": () => import("./../../../src/pages/blog/aplikacje-webowe-vs-strony-internetowe.js" /* webpackChunkName: "component---src-pages-blog-aplikacje-webowe-vs-strony-internetowe-js" */),
  "component---src-pages-blog-cms-vs-strona-pisana-od-podstaw-js": () => import("./../../../src/pages/blog/cms-vs-strona-pisana-od-podstaw.js" /* webpackChunkName: "component---src-pages-blog-cms-vs-strona-pisana-od-podstaw-js" */),
  "component---src-pages-blog-czym-jest-responsywnosc-strony-js": () => import("./../../../src/pages/blog/czym-jest-responsywnosc-strony.js" /* webpackChunkName: "component---src-pages-blog-czym-jest-responsywnosc-strony-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-jak-przebiega-proces-tworzenia-strony-js": () => import("./../../../src/pages/blog/jak-przebiega-proces-tworzenia-strony.js" /* webpackChunkName: "component---src-pages-blog-jak-przebiega-proces-tworzenia-strony-js" */),
  "component---src-pages-blog-jak-wybrac-idealna-strone-js": () => import("./../../../src/pages/blog/jak-wybrac-idealna-strone.js" /* webpackChunkName: "component---src-pages-blog-jak-wybrac-idealna-strone-js" */),
  "component---src-pages-blog-jak-wybrac-najlepszy-hosting-i-domene-js": () => import("./../../../src/pages/blog/jak-wybrac-najlepszy-hosting-i-domene.js" /* webpackChunkName: "component---src-pages-blog-jak-wybrac-najlepszy-hosting-i-domene-js" */),
  "component---src-pages-blog-jakie-funkcje-powinna-miec-nowoczesna-strona-js": () => import("./../../../src/pages/blog/jakie-funkcje-powinna-miec-nowoczesna-strona.js" /* webpackChunkName: "component---src-pages-blog-jakie-funkcje-powinna-miec-nowoczesna-strona-js" */),
  "component---src-pages-formularz-szybkiej-wyceny-js": () => import("./../../../src/pages/formularz-szybkiej-wyceny.js" /* webpackChunkName: "component---src-pages-formularz-szybkiej-wyceny-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projektowanie-logo-js": () => import("./../../../src/pages/projektowanie-logo.js" /* webpackChunkName: "component---src-pages-projektowanie-logo-js" */),
  "component---src-pages-sklepy-internetowe-js": () => import("./../../../src/pages/sklepy-internetowe.js" /* webpackChunkName: "component---src-pages-sklepy-internetowe-js" */),
  "component---src-pages-strony-internetowe-gdansk-js": () => import("./../../../src/pages/strony-internetowe/gdansk.js" /* webpackChunkName: "component---src-pages-strony-internetowe-gdansk-js" */),
  "component---src-pages-strony-internetowe-index-js": () => import("./../../../src/pages/strony-internetowe/index.js" /* webpackChunkName: "component---src-pages-strony-internetowe-index-js" */),
  "component---src-pages-strony-internetowe-katowice-js": () => import("./../../../src/pages/strony-internetowe/katowice.js" /* webpackChunkName: "component---src-pages-strony-internetowe-katowice-js" */),
  "component---src-pages-strony-internetowe-krakow-js": () => import("./../../../src/pages/strony-internetowe/krakow.js" /* webpackChunkName: "component---src-pages-strony-internetowe-krakow-js" */),
  "component---src-pages-strony-internetowe-poznan-js": () => import("./../../../src/pages/strony-internetowe/poznan.js" /* webpackChunkName: "component---src-pages-strony-internetowe-poznan-js" */),
  "component---src-pages-strony-internetowe-warszawa-js": () => import("./../../../src/pages/strony-internetowe/warszawa.js" /* webpackChunkName: "component---src-pages-strony-internetowe-warszawa-js" */),
  "component---src-pages-strony-internetowe-wroclaw-js": () => import("./../../../src/pages/strony-internetowe/wroclaw.js" /* webpackChunkName: "component---src-pages-strony-internetowe-wroclaw-js" */),
  "component---src-pages-strony-lms-js": () => import("./../../../src/pages/strony-lms.js" /* webpackChunkName: "component---src-pages-strony-lms-js" */),
  "component---src-pages-strony-niestandardowe-js": () => import("./../../../src/pages/strony-niestandardowe.js" /* webpackChunkName: "component---src-pages-strony-niestandardowe-js" */),
  "component---src-pages-strony-wordpress-js": () => import("./../../../src/pages/strony-wordpress.js" /* webpackChunkName: "component---src-pages-strony-wordpress-js" */)
}

